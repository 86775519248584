





















































import { Component, Vue, Watch } from "vue-property-decorator";
import Menu from "@/views/Menu.vue";
import Icon from "@/components/reusable/Icon.vue";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { EventBus } from "@/events/index";
import Auth from "@/components/Auth.vue";
import { GlobalGetters, GlobalActions } from "@/store/modules/global/types";
import UIkit from 'uikit';
@Component({
  components: {
    Menu,
    Icon,
    Auth
  }
})
export default class Main extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;
  protected messageHtmlFail = "";
  protected messageHtmlSuccess = "";
  protected messageHtmlError = "";
  protected showSpinner = false;
  protected showSuccess = false;
  protected showFail = false;
  protected showError = false;
  protected success: string[] = [];
  protected failed: string[] = [];
  protected conflicted: string[] = [];

  @Watch("$route.query", { deep: true, immediate: true })
  protected onQueryChange() {
    this.$nextTick(() => {
      if (this.$route.query.deleted) {
        EventBus.$emit(
          "showSuccess",
          `${decodeURIComponent(this.$route.query.deleted as string)} ${
            this.$route.query.type ? this.$route.query.type : "item"
          } deleted successfully.`,
          []
        );
      } else if (this.$route.query.created) {
        EventBus.$emit(
          "showSuccess",
          `${decodeURIComponent(this.$route.query.created as string)} ${
            this.$route.query.type ? this.$route.query.type : "item"
          } created successfully.`,
          []
        );
      }
    });
  }

  created(): void {
    EventBus.$on("showError", (errorMsg: string): void => {
      this.messageHtmlError = errorMsg;
      this.showError = true;
    });
  }

  /**
   * "showError" global event
   * Triggers error toast
   * This is a global event, so is called from any & all error events within the app to display errors
   *
   * @param errorMsg message caught from API services. It is either string or HTML as string
   */
  mounted(): void {
    EventBus.$on("showError", (errorMsg: string): void => {
      this.messageHtmlError = errorMsg;
      this.showError = true;
      this.setLoading(false);
    });
    EventBus.$on(
      "showFail",
      (msg: string, data: string[], conflicted?: string[]): void => {
        this.messageHtmlFail = msg;
        this.failed = data;
        if (conflicted) {
          this.conflicted = conflicted;
        }
        this.showFail = true;
      }
    );
    EventBus.$on("showSuccess", (msg: string, data: string[]): void => {
      this.messageHtmlSuccess = msg;
      this.success = data;
      this.showSuccess = true;
    });
    EventBus.$on("showNotification", (html: string): void => {
      UIkit.notification(html)
    });
  }

  beforeDestroy() {
    EventBus.$off("showError");
    EventBus.$off("showFail");
    EventBus.$off("showSuccess");
    EventBus.$off("showNotification")
  }

  protected closeToastError(): void {
    this.showError = false;
  }

  protected closeToastFail(): void {
    this.conflicted = [];
    this.failed = [];
    this.showFail = false;
  }

  protected closeToastSuccess(): void {
    this.success = [];
    this.showSuccess = false;
  }
}
