import { APIError, AuthError } from "@/services/error_service";

import Vue from "vue";
import axios from "axios";

/**
 * Creates a new Auth Service

 * @example
 * const authService = new AuthService();
 * authService.getauth();
 */

export default class AuthService {
  /**
   * POST to auth server
   * @param {string} code - oauth 2.0 auth code
   * @returns {viod}
   */
  public async login(code: string): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const body = JSON.stringify({
      access_code: code,
      redirect_uri: encodeURI(process.env.VUE_APP_AUTH_URL)
    });
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/auth/login",
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  public async checkAuth(): Promise<void> {
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/auth/introspect"
      );
    } catch (err) {
      throw new Error();
    }
  }
}
